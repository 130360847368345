<template>
    <ODropdown v-if="row.error">
        <template #default="{target, open}">
            <button :ref="target" class="btn btn-sm btn-link p-0" @click="open">
                <i class="text-danger bi bi-exclamation-triangle-fill" :title="row.error"></i>
            </button>
        </template>
        <template #dropdown="{container, close}">
            <div class="dropdown-menu show" :ref="container" style="max-width: 350px">
                <h6 class="dropdown-header pb-0 text-danger">{{ $t('Error') }}</h6>
                <p class="mx-3 my-0 text-danger text-wrap">{{ row.error }}</p>
                <hr class="dropdown-divider">
                <template v-if="row.hasChanges">
                    <button class="dropdown-item" @click="() => { row.removeError(); close(); }">
                        <i class="bi bi-pencil me-1"></i>
                        {{$t('Edit Again')}}
                    </button>
                    <button v-if="row.isNewRecord" class="dropdown-item" @click="() => { row.cancelChanges(); close(); }">
                        <i class="bi bi-x-lg me-1"></i>
                        {{$t('Remove Row')}}
                    </button>
                    <button v-else class="dropdown-item"  @click="() => { row.cancelChanges(); close(); }">
                        <i class="bi bi-arrow-counterclockwise me-1"></i>
                        {{$t('Cancel Changes')}}
                    </button>
                </template>
                <template v-else>
                    <button class="dropdown-item" @click="() => { row.removeError(); close(); }">
                        {{$t('Remove Error')}}
                    </button>
                </template>
            </div>
        </template> 
    </ODropdown>
</template>

<script setup lang="ts">
import type { DataItemModel } from 'o365.modules.DataObject.Types.ts';
import ODropdown from 'o365.vue.components.DropDown.vue';
import { defineComponent } from 'vue';

const props = defineProps<{
    row: DataItemModel,
}>();

</script>

<script lang="ts">

export default defineComponent({
    inheritAttrs: false
});
</script>